.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.innerdata tr th {
  font-weight: 700;
}

.avatar-img {
  padding: 0px !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}


/* styles.css */
.h2-text-style {
  font-size: 24px;
  color: green;
}

.card-body table thead tr th {
  font-size: 16px;
}

.sidebar-nav .nav-link {
  font-size: 17px;
}

.dropdown-item:focus,
.dropdown-item:active {
  background-color: #1e1b1b !important;
}

.dropdown-item:focus .nav-link,
.dropdown-item:active .nav-link {
  color: #fff !important;
}

.preview-img {
  max-width: 100px;
  max-height: 100px;
}

.imageDiv {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px !important;
  object-fit: contain;
  gap: 12px !important;
}

.imageDiv div {
  display: flex;
}

.imageDiv div svg {
  top: 0px;
  left: 3px;
  cursor: pointer;
  border-radius: 10px;
  margin-left: 5px;
  font-size: 20px;
  background-color: red;
  color: #fff;
}

.pdf-icon {
  font-size: 13px;
  padding-top: 8px;
}

.pdf-icon svg {
  color: red;
  font-size: 18px;
  position: relative;
  top: 0px;
  margin-right: 2px;
}
.file_preview{
  width: 100% !important;
}

.manage_box {
  border: 1px solid #b1b7c1 !important;
  margin-bottom: 15px;
  border-radius: 5px;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8); /* Optional: adds a light overlay */
  z-index: 1000; /* Ensures the loader stays on top */
}

.spinner {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media(max-width: 991.98px){
  .file_preview{
    height: 256px !important;
  }
}
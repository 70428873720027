// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
// @import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";



.css-1ex1afd-MuiTableCell-root {
    border-left: 0px;
    border-right: 0px;
}

.css-1yhpg23-MuiTableCell-root {
    border-right: 0px;
}

.table-bordered thead tr th {
    border-left: 0;
    border-right: 0px;
}

.table-bordered tbody tr th+th {font-weight: normal;}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
    box-shadow: 0px 4px 8px #cccccc78 !important;
}

a.gmail {
    text-decoration: none;
    color: #121212;
    padding-left: 10px;
    font-size: 16px;
}
.table-bordered>tbody>tr>td {
    border-right: 0px;
    border-left: 0px;
}
.MuiPaper-rounded {
    box-shadow: 0px 4px 8px rgba(204, 204, 204, 0.4705882353) !important;
}
.btn-primary {
    background: #1e1b1b;
    border-color: #1e1b1b;
}

.btn-primary:hover, .btn-primary:focus {
    background: #000 !important;
    border-color: #000 !important;
}